@import "variables";

@media (max-width: $bp-medium) {
  .border-dual-color {
    border: none;
  }
  .footer-padding {
    padding: 0 !important;
  }
  #footer {
    position: relative;
    padding: 691px 0 0 0;
    border-top: none;
  }
  #footer:before {
    position: static;
    height: 0;
  }
  #footer .formas-pagamento {
    border-top: 1px solid #888 !important;
    padding-top: 50px;
    text-align: center;
  }
  #footer .formas-pagamento h5 {
    text-align: center;
    padding: 0 57px;
  }
  #footer .formas-pagamento p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 24px;
    color: #000;
    line-height: 30px;
    padding: 0 57px;
    text-align: center;
    width: auto !important;
  }
  #footer .formas-pagamento ul {
    padding: 0 40px;
    margin: 20px 0 0 0;
    text-align: center;
  }

  #footer .formas-pagamento ul li {
    width: 96px;
    height: 42px;
    margin: 0 !important;
    list-style: none;
    display: inline-block;
    vertical-align: top;
    background: url('../img/formas-de-pagamentos-mobile.jpg');
  }
  #footer .formas-pagamento ul li.visa {
    background-position: -96px 0;
  }
  #footer .formas-pagamento ul li.american-express {
    background-position: -192px 0;
  }
  #footer .formas-pagamento ul li.boleto {
    background-position: -288px 0;
  }
  #footer .formas-pagamento ul li.onebuy {
    background-position: -384px 0;
  }
  #footer .entrega {
    padding: 30px 57px 0 57px;
  }
  #footer .entrega h5 {
    text-align: center;
    padding-left: 15px;
    margin-bottom: 40px;
  }
  #footer .entrega ul li {
    list-style: none;
    margin: 16px 0;
  }
  #footer .entrega ul li h6 {
    margin: auto auto 10px auto;
    text-align: left;
    padding-left: 15px;
  }
  #footer .entrega ul li .img {
    width: 50px;
    height: auto;
    margin: auto auto 20px auto;
    text-align: center;
  }
  #footer .entrega ul li div {
    width: 450px;
    margin: auto;
    text-align: left;
  }
  #footer .entrega ul li div .text {
    width: auto !important;
    text-align: left;
    padding-left: 15px;
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 24px;
    color: #4f2c1e;
    line-height: 24px;
  }
  #footer .large ul li {
    width: 100% !important;
  }
  #footer .novidades {
    padding: 100px 0 0 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  }
  #footer .novidades h5 {
    padding: 0 20px;
  }
  #footer .novidades .form-footer {
    padding: 0 57px 130px 57px;
  }
  #footer .novidades .form-footer button {
    font-size: 27px;
  }
  #footer .novidades .redes-sociais {
    margin-top: 100px;
    padding-bottom: 130px;
    text-align: center;
  }
  #footer .novidades .redes-sociais ul {
    text-align: center;
  }
  #footer .novidades .redes-sociais ul li {
    width: 68px;
    height: 42px;
    margin: 0 9px;
    list-style: none;
    display: inline-block;
    vertical-align: top;
  }
  #footer .novidades .redes-sociais ul li:first-child {
    margin: 0 9px 0 0;
  }
  #footer .novidades .redes-sociais ul li a {
    display: block;
    width: 68px;
    height: 42px;
    background: url("../img/../img/redes-sociais-mobile.png");
  }
  #footer .novidades .redes-sociais ul li a.twitter {
    background-position: -68px 0;
  }
  #footer .novidades .redes-sociais ul li a.instagram {
    background-position: -136px 0;
  }
  #footer .novidades .redes-sociais ul li a.youtube {
    background-position: -204px 0;
  }
  #footer .novidades .redes-sociais ul li a.tripadvisor {
    background-position: -272px 0;
  }
  #footer .whatsapp-button, .pulse-button {
    width: 50px;
    height: 50px;
    line-height: 50px;

    &::after,
    &::before {
      display: none !important;
    }
  }

  .rodape {
    text-align: center;
    margin: 25px 0;
  }
  .rodape p {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 15px;
    color: #888;
  }
  body {
    padding: 120px 0 0 0;
    font-size: 26px;
  }
  body.checkout {
    padding-top: 250px;
  }
  h1 {
    font-size: 48px;
  }
  h2 {
    font-family: 'martelregular';
    font-weight: normal;
    font-size: 48px;
    color: #4f2c1e;
  }
  h2.pink {
    color: #ed19d7;
  }
  h2.orange {
    color: #ff5a12;
  }
  h2.purple {
    color: #513557;
  }
  h3 {
    font-size: 26px;
  }
  h3.border {
    font-size: 47px;
    margin: 70px 0 0 0;
    padding: 35px 0 60px;
    text-align: center;
  }
  h3.border:after {
    width: 236px;
    height: 5px;
    margin-left: -118px;
    left: 50%;
    top: 0;
  }
  h4 {
    font-size: 36px;
  }
  h4.margin {
    margin-left: 0;
    margin-bottom: 36px;
    font-size: 36px;
    text-align: center;
  }
  h5 {
    font-size: 36px;
  }
  h6 {
    font-size: 24px;
  }
  p {
    line-height: normal;
    font-size: 26px;
  }
  form:not(.page-birthday-form) .form-group {
    margin-bottom: 30px;
  }
  form:not(.page-birthday-form) p.help-block,
  form:not(.page-birthday-form) a.help-block {
    line-height: 22px;
    font-size: 15px;
    font-family: 'martelregular';
  }
  form:not(.page-birthday-form) label {
    line-height: 30px;
    font-size: 20px;
    font-family: 'martelregular';
  }
  form:not(.page-birthday-form) label.radio-inline {
    margin-top: 8px !important;
    font-size: 20px;
  }
  form:not(.page-birthday-form) label.checkbox-inline {
    font-size: 15px;
    color: #4f2c1e;
  }
  form:not(.page-birthday-form) input[type="text"],
  form:not(.page-birthday-form) input[type="email"],
  form:not(.page-birthday-form) input[type="password"],
  form:not(.page-birthday-form) input[type="number"],
  form:not(.page-birthday-form) select {
    height: 60px;
    line-height: 30px;
    padding: 18px 25px 12px;
    font-size: 20px;
  }
  .btn {
    height: 70px;
    line-height: 70px;
    padding: 0 20px;
    border: #9b7f81 3px solid;
    font-size: 21px;
  }
  .container {
    width: 100%;
  }
  .container.small {
    width: 640px;
  }
  .nav-tabs {
    padding: 0 10px;
  }
  .nav-tabs li a {
    height: 60px;
    line-height: 62px;
    font-size: 20px;
    color: #3c2016;
  }
  .nav-tabs li.active a {
    color: #3c2016;
  }
  .nav-tabs li.active a:hover,
  .nav-tabs li.active a:focus {
    color: #3c2016;
  }
  .modal .modal-dialog {
    width: 620px;
    margin: 100px 0;
    -moz-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    top: 0;
    position: absolute;
  }
  .modal .modal-dialog.big {
    width: 620px;
  }
  .modal.in .modal-dialog,
  .modal.fade .modal-dialog {
    -moz-transform: translate(-50%, 0);
    -webkit-transform: translate(-50%, 0);
    -o-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  .modal .modal-content .modal-header {
    height: 60px;
    line-height: 64px;
    font-size: 26px;
  }
  .modal .modal-content .modal-header .close {
    width: 40px;
    height: 40px;
    top: -60px;
    right: 14px;
  }
  .modal .modal-content .modal-header .close:before {
    width: 48px;
    height: 48px;
    border-right: #fff 2px solid;
    top: -24px;
    left: -24px;
  }
  .modal .modal-content .modal-header .close:after {
    width: 48px;
    height: 48px;
    border-right: #fff 2px solid;
    top: 8px;
    left: -22px;
  }
  #img-header .img {
    height: 470px;
  }
  .central-title {
    padding-top: 1em;
    margin: 40px auto 40px auto;
  }
  .central-title h2 {
    text-align: center;
  }
  .central-title h2 span {
    width: auto;
    text-align: center;
    position: relative;
    display: inline-block;
    line-height: 36px;
    letter-spacing: 2px;
  }
  .central-title h2 span:after {
    position: absolute;
    content: ' ';
    width: calc((620px - 100%) / 2);
    height: 1px;
    background: #d1d1d1;
    top: 15px;
    right: calc((-640px + 100%) / 2);
    left: auto;
  }
  .central-title h2 span:before {
    position: absolute;
    content: ' ';
    width: calc((620px - 100%) / 2);
    height: 1px;
    background: #d1d1d1;
    top: 15px;
    left: calc((-640px + 100%) / 2);
    right: auto;
  }
  .central-title .description {
    font-size: 26px;
    line-height: 28px;
    margin: 30px auto 30px;
  }
  .btn-link {
    font-size: 20px;
  }
  .btn-link.black {
    font-size: 20px;
  }
  .white {
      background: #fff !important;
  }

  .navbar .container {
    width: 100%;
  }
  .navbar.navbar-default {
    height: 130px;
    border-width: 3px;
    border-left: 0;
    border-right: 0;
  }
  .navbar:after {
    height: 4px !important;
    background: #ae1f6f !important;
    bottom: -7px !important;
  }
  .navbar .open-menu {
    margin-top: 42px;
    margin-left: 0;
  }
  .navbar .logo {
    width: auto !important;
    height: 96px !important;
    margin-top: 16px !important;
    margin-left: -170px !important;
    left: 50% !important;
  }
  .navbar .logo img {
    width: auto;
    height: 96px !important;
  }
  .navbar.single .logo {
    margin-left: -86px !important;
  }
  .navbar .steps {
    width: 100%;
    margin: 0;
    background: #fff;
    padding: 30px 0;
    float: none;
    top: 124px;
    left: 0;
    position: absolute;
  }
  .navbar .steps ul {
    width: 100%;
    margin: auto;
  }
  .navbar .steps ul li {
    width: 24%;
    margin: 0 0.5%;
  }
  .navbar .steps ul li:after {
    width: 100%;
    top: 16px;
  }
  .navbar .steps ul li .number {
    width: 34px;
    height: 34px;
    line-height: 34px;
    -webkit-border-radius: 17px;
    -moz-border-radius: 17px;
    border-radius: 17px;
    margin-bottom: 15px;
    font-size: 20px;
    z-index: 100;
  }
  .navbar .steps ul li .number:after {
    width: 8px;
    height: 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    margin: -4px 0 0 -4px;
    top: 50%;
  }
  .navbar .steps ul li.checked .number {
    background: #775ca7;
  }
  .navbar .steps ul li.checked .number:after {
    display: none;
  }
  .navbar .steps ul li .text {
    font-size: 13px;
  }
  .navbar .right .fav {
    opacity: 0.5;
    -moz-transform: scale(1.8);
    -webkit-transform: scale(1.8);
    -o-transform: scale(1.8);
    -ms-transform: scale(1.8);
    transform: scale(1.8);
    -webkit-transform: scale(1.8);
    -moz-transform: scale(1.8);
  }
  .navbar .right .bag {
    margin-left: 50px;
    float: right;
  }
  .navbar .right .bag .count {
    margin: 0 !important;
    top: 24px;
    right: -17px;
    width: 35px;
    height: 35px;
    line-height: 35px;
    font-size: 18px;
  }
  #menu-mobile {
    top: 134px !important;
  }
  #menu-mobile .logado span {
    display: block;
    padding: 20px 0 0 20px;
    font-size: 32px;
    color: #9a8137;
  }
  #menu-mobile p > a {
    color: #4f2c1e;
  }
  .txt-smlr {
      text-align: center;
      font-size: 11px;
      line-height: 15px;
  }

  .navbar.checkout .steps {
      width: 100%;
  }

  .label-error-message {
      padding: 0;
      height: 36px;
      display: none;
      margin-bottom: 14px;
  }
  .label-error-message label {
      line-height: 18px;
  }
}